import { Swiper } from 'swiper/react';
import React from 'react';
import { IComponentWithChildren } from 'types/app';
import { SliderWrapper } from 'components/slider/Slider.styled';

import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper as SwiperClass } from 'swiper/types';

export interface ISlider extends IComponentWithChildren {
  nameClass?: string;
  onReachEnd?: () => void;
  onInit?: (swiper: SwiperClass) => void;
  onSlideChangeTransitionEnd?: (swiper: SwiperClass) => void;
  onSlideChange?: (params: any) => void;
  hasPagination?: boolean;
  allowTouchMove?: boolean;
  slidesPerView?: number | 'auto';
  spaceBetween?: number;
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
  loop?: boolean;
  initialSlide?: number;
}

const Slider: React.FC<ISlider> = ({
  hasPagination,
  children,
  onReachEnd,
  onInit,
  onSlideChangeTransitionEnd,
  onSlideChange,
  slidesPerView,
  spaceBetween,
  slidesOffsetBefore,
  slidesOffsetAfter,
  loop,
  initialSlide,
  allowTouchMove = true,
  nameClass = '',
}: ISlider) => {
  return (
    <SliderWrapper>
      <Swiper
        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
        onSlideChange={onSlideChange}
        onReachEnd={onReachEnd}
        onSwiper={onInit}
        pagination={hasPagination}
        // modules={[Pagination]}
        allowTouchMove={allowTouchMove}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        className={nameClass}
        slidesOffsetBefore={slidesOffsetBefore}
        slidesOffsetAfter={slidesOffsetAfter}
        loop={loop}
        initialSlide={initialSlide}
      >
        {children}
      </Swiper>
    </SliderWrapper>
  );
};

export default Slider;
