import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { AppVideoPlayerWrapper } from 'components/videoplayer/AppVideoPlayer.styled';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import ButtonClose from 'components/common/button/ButtonClose';
import { getId } from 'helpers/utils';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';

const VIDEO_RATIO = 1.77;
const VIDEO_VMARGIN = 75 * 2;
// Old Viméo video
const VIDEO_PORTRAIT = [
  'tuto-contact',
  'tuto-rendez-vous',
  'tuto-motivation',
  'tuto-relations',
  'module-notification_management',
  'module-notification_rdv',
  'module-notification_motivation'
];

interface ReactPlayerProps {
  url: string;
  width: string;
  height: string;
  playing?: boolean;
  controls?: boolean;
  config?: {
    youtube: {
      playerVars: {
        showinfo: number;
      };
    };
  };
}

const AppVideoPlayer: React.FC = () => {
  const { videoUrl, setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { t } = useTranslation('translation');
  const { rollbar } = useRollbar();

  const onClose = () => {
    setVideoUrl(undefined);
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [videoProps, setVideoProps] = useState<ReactPlayerProps>();
  const resizeHandler = () => {
    if (!videoUrl) {
      return;
    }

    setTimeout(() => {
      const isShort = videoUrl?.includes('short') || VIDEO_PORTRAIT.some(vidName => videoUrl?.includes(vidName));
      const width = '100%';
      let height = '100%';

      if (window.innerHeight >= window.innerWidth) {
        if (!isShort) {
          const targetHeight = Math.floor(window.innerWidth / VIDEO_RATIO) + VIDEO_VMARGIN;

          height = `${Math.min(window.innerHeight, targetHeight)}px`;

          console.log('isShort: ', isShort , 'video height: ', height, ', window.innerHeight: ', window.innerHeight, ', targetHeight: ', targetHeight, ', window.innerWidth: ', window.innerWidth);
        }
      }

      setVideoProps({
        url: videoUrl,
        width,
        height,
        playing: true,
        controls: true,
        config: {
          youtube: {
            playerVars: {
              showinfo: 0,
            },
          },
        },
      });
    }, 100);
  };

  useEffect(() => {
    videoUrl && resizeHandler();
  }, [videoUrl]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  // Function to check if it's a TikTok link
  function isTikTokLink(url: string): boolean {
    const tiktokPattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/(@[\w.-]+\/video\/\d+).*$/;
    return tiktokPattern.test(url);
  }

  // Function to check if it's already an embed TikTok link
  function isTikTokEmbedLink(url: string): boolean {
    const embedV1Pattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/embed\/\d+$/;
    const embedV2Pattern = /^(https?:\/\/)?(www\.)?tiktok\.com\/embed\/v2\/\d+$/;
    return embedV1Pattern.test(url) || embedV2Pattern.test(url);
  }

  // Function to transform TikTok link to embed link
  function transformToEmbedLink(url: string): string {
    if (isTikTokEmbedLink(url)) {
      return url; // Already an embed link
    }

    const match = url.match(/\/@[\w.-]+\/video\/(\d+)/);
    if (match) {
      const videoId = match[1];
      return `https://www.tiktok.com/embed/v2/${videoId}`;
    }

    // Return original URL if it doesn't match any pattern
    return url;
  }

  return (
    <AppVideoPlayerWrapper className={videoUrl ? 'open' : 'close'}>
      <div className="btn-close-wrapper">
        <ButtonClose onClick={onClose} />
      </div>
      {videoUrl && (
        <>
          {isTikTokLink(videoUrl) || isTikTokEmbedLink(videoUrl) ? (
            <>
              <div
                className="tiktok-container"
                style={{ height: '100vh', overflow: 'hidden', position: 'relative' }}
              >
                <iframe
                  className="tiktok-iframe"
                  src={transformToEmbedLink(videoUrl)}
                  allowFullScreen
                  title="TikTok Video"
                  allow="encrypted-media;"
                ></iframe>
              </div>
            </>
          ) : (
            <ReactPlayer
              onError={(err) => {
                console.error(err);
                console.error('Video url', videoUrl);
                rollbar.error(`${videoUrl}`, err);
                setVideoUrl(undefined);
                setToast({
                  message: t('message.error.cannotplayvideo'),
                  status: 'warning',
                });
              }}
              {...videoProps}
              playsinline={true}
              key={getId()}
            />
          )}
        </>
      )}
    </AppVideoPlayerWrapper>
  );
};

export default AppVideoPlayer;
