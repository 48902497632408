import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/constants/devices';
import { prem } from 'styles/helpers/spacing';

const AppVideoPlayerWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 300;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
    pointer-events: auto;
  }

  &.close {
    display: none;
    pointer-events: none;
  }

  .btn-close-wrapper {
    pointer-events: auto;
    position: absolute;
    right: ${prem(10)};
    top: ${prem(10)};
    background: black;
    width: ${prem(40)};
    height: ${prem(40)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    z-index: 999;

    svg {
      path {
        stroke: white;
        stroke-width: 3;
      }
    }
  }

  .tiktok-container {
    .tiktok-iframe {
      width: 110%;
      max-width: 100%;
      height: 574px;
      max-height: 86%;
      border: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8);

      @media (${BREAKPOINTS.SM}) {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
`;

export { AppVideoPlayerWrapper };
